export enum AttributeType {
	list = 'list',
	int = 'int',
	number = 'number',
	string = 'string',
	date = 'date',
	bool = 'bool'
}

export enum AttributeSource {
	base = 'base',
	attr = 'attr'
}

export enum AttributeCategory {
	scm = 'scm'
}

export interface AttributeConfiguration {
	attr_id: number;
	name: string;
	type: AttributeType;
	uom?: string;
	field: string;
	source: AttributeSource;
	order: number;
	group: string;
	options: number[];
	category: AttributeCategory;
	required: boolean;
	raw_part_field?: string;
	variations: AttributeConfigurationVariation[];
}

export interface AttributeConfigurations {
	attributes: AttributeConfiguration[];
	options: AttributeOption[];
}

export interface AttributeOption {
	attr_option_id: number;
	name: string;
}

export interface AttributeConfigurationCondition {
	attr_id: number;
	attr_option_id: number;
}

export interface AttributeConfigurationVariation {
	attr_variation_id: number;
	attr_id: number;
	parent_attr_id?: number;
	parent_option_id?: number;
	parent_attr_variation_id?: number;
	options: number[];
	required: boolean;
	conditions: AttributeConfigurationCondition[];
}
