export enum RawPartField {
	id = 'id',
	UID = 'UID',
	Classification = 'Classification',
	Primary_Commodity = 'Primary_Commodity',
	Shape = 'Shape',
	Secondary_Commodity = 'Secondary_Commodity',
	DateTimeStamp = 'DateTimeStamp',
	CI_Region = 'CI_Region',
	CI_Site_Code = 'CI_Site_Code',
	CI_Customer = 'CI_Customer',
	CI_Customer_PN = 'CI_Customer_PN',
	CI_Drawing_Number = 'CI_Drawing_Number',
	CI_Drawing_Revision = 'CI_Drawing_Revision',
	CI_JPN = 'CI_JPN',
	CI_Supplier = 'CI_Supplier',
	CI_Supplier_Country = 'CI_Supplier_Country',
	CI_Country_of_Origin = 'CI_Country_of_Origin',
	CI_Minimum_Order_Qty_MOQ = 'CI_Minimum_Order_Qty_MOQ',
	CI_Est_Annual_Usage_EAU = 'CI_Est_Annual_Usage_EAU',
	CI_Quoted_Price = 'CI_Quoted_Price',
	MTR_Primary_Material = 'MTR_Primary_Material',
	MTR_Color = 'MTR_Color',
	PI_Length_mm = 'PI_Length_mm',
	PI_Width_mm = 'PI_Width_mm',
	PI_Height_mm = 'PI_Height_mm',
	PI_Least_Tolerance_mm = 'PI_Least_Tolerance_mm',
	PI_Total_No_of_tolerance = 'PI_Total_No_of_tolerance',
	PI_No_of_CTF_Dimensions = 'PI_No_of_CTF_Dimensions',
	PI_Development_Length_mm = 'PI_Development_Length_mm',
	PI_Development_Width_mm = 'PI_Development_Width_mm',
	PI_Inner_Dia_mm = 'PI_Inner_Dia_mm',
	PI_Outer_Dia_mm = 'PI_Outer_Dia_mm',
	PI_Max_Thickness_mm = 'PI_Max_Thickness_mm',
	PI_Gear_Type = 'PI_Gear_Type',
	PI_No_of_Teeth = 'PI_No_of_Teeth',
	PI_Part_Weight_gr = 'PI_Part_Weight_gr',
	PI_Runner_Weight_gr = 'PI_Runner_Weight_gr',
	PI_Waste_Material_Percentage = 'PI_Waste_Material_Percentage',
	PI_Hardness = 'PI_Hardness',
	PI_No_of_holes = 'PI_No_of_holes',
	PI_Childparts = 'PI_Childparts',
	PI_Effective_Area_Percentage = 'PI_Effective_Area_Percentage',
	PI_No_of_Thread = 'PI_No_of_Thread',
	PI_No_of_slots = 'PI_No_of_slots',
	PI_No_of_non_plastic_components = 'PI_No_of_non_plastic_components',
	MTD_Cavitation = 'MTD_Cavitation',
	MTD_Tonnage = 'MTD_Tonnage',
	MTD_Cycle_Time = 'MTD_Cycle_Time',
	MTD_Scrap_Percentage = 'MTD_Scrap_Percentage',
	MTD_Additional_Machining_Axis = 'MTD_Additional_Machining_Axis',
	MTD_Surface_Roughness_m = 'MTD_Surface_Roughness_m',
	MTD_No_of_bend = 'MTD_No_of_bend',
	MTD_No_of_Forming = 'MTD_No_of_Forming',
	MTD_No_of_Weldings = 'MTD_No_of_Weldings',
	MTD_Weld_Length_mm = 'MTD_Weld_Length_mm',
	SOF_Primary_Finish = 'SOF_Primary_Finish',
	SOF_Secondary_Finish = 'SOF_Secondary_Finish',
	SOF_No_of_Additional_Operations = 'SOF_No_of_Additional_Operations',
	SOF_Heat_Staking = 'SOF_Heat_Staking',
	SOF_Hot_Stamping = 'SOF_Hot_Stamping',
	SOF_Inserts = 'SOF_Inserts',
	SOF_Label = 'SOF_Label',
	SOF_Laser_Engraving = 'SOF_Laser_Engraving',
	SOF_Pad_Printing = 'SOF_Pad_Printing',
	SOF_Painting = 'SOF_Painting',
	SOF_Post_mold_machining = 'SOF_Post_mold_machining',
	SOF_Protective_Film = 'SOF_Protective_Film',
	SOF_Silk_Screening = 'SOF_Silk_Screening',
	SOF_Ultrasonic_Welding = 'SOF_Ultrasonic_Welding',
	SOF_UV_Coating = 'SOF_UV_Coating',
	SOF_SPI_Finish = 'SOF_SPI_Finish',
	SOF_Cleanliness = 'SOF_Cleanliness',
	SOF_Marking = 'SOF_Marking',
	OTH_Primary_Method_of_Assembly = 'OTH_Primary_Method_of_Assembly',
	OTH_Secondary_Material = 'OTH_Secondary_Material',
	MTR_Primary_Material_Translation = 'MTR_Primary_Material_Translation',
	PI_No_of_Child_or_Assembly_Parts = 'PI_No_of_Child_or_Assembly_Parts'
}
